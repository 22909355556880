import type { ReactNode } from 'react';
import React from 'react';
import type { StandaloneEssentials } from '../api';
import { EssentialsProvider } from '@wix/fe-essentials/react';

export interface RootEssentialsProviderWrapperProps {
  essentials: StandaloneEssentials;
  children?: ReactNode;
}

export const RootEssentialsWrapperProvider = ({
  essentials,
  children,
}: RootEssentialsProviderWrapperProps) => {
  return (
    <EssentialsProvider essentials={essentials}>{children}</EssentialsProvider>
  );
};
