(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"));
	else if(typeof define === 'function' && define.amd)
		define("WixEssentials", ["React"], factory);
	else if(typeof exports === 'object')
		exports["WixEssentials"] = factory(require("React"));
	else
		root["WixEssentials"] = factory(root["React"]);
})((typeof self !== 'undefined' ? self : this), (__WEBPACK_EXTERNAL_MODULE__1024__) => {
return 