import {
  RootEssentialsOptions,
  StandaloneRootEssentials,
} from './StandaloneRootEssentials';
import type { StandaloneEssentials } from './standaloneEssentials';

export interface CreateStandaloneEssentialsOptions
  extends RootEssentialsOptions {}

export function createEssentials(
  options: CreateStandaloneEssentialsOptions,
): StandaloneEssentials {
  return new StandaloneRootEssentials(options);
}
